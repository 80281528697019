import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import * as style from "../styles/mind.module.scss"
import HDLogo from "../images/logo.png"

const Mind = () => {
  return (
    <Layout>
      <Seo
        title="Mind | 株式会社アローズ"
        description="私たちができること向かっていく未来。消費者が「欲しい」「買いたい」と思う製品をゼロから開発、消費者が求めるモノがあれば世界中から探しだす。"
      />
      <div id="mind">
        <div className={style.mv}>
          <div className={style.inner}>
            <h1>MIMD</h1>
            <h2 className={style.catch}>私たちの思い</h2>
          </div>
        </div>
      </div>
      <section className={style.pageMind}>
        <h2>
          私たちができること
          <br />
          向かっていく未来
        </h2>
        <h3>
          消費者が「欲しい」「買いたい」と思う製品をゼロから開発、
          <br />
          消費者が求めるモノがあれば世界中から探しだす。
        </h3>
        <p>
          我々のビジネスフィールドである通販市場は、その媒体を常に変化させながら拡大し続けています。
          <br />
          日本でネット通販が普及する20年以上前から、アローズは通販市場にヒット商品を提供し続けてきました。
          <br />
          カタログ通販に始まり、テレビ、インターネット、ＳＮＳと通販媒体は時代とともに変貌し、進化し続けています。
          <br />
          通販媒体がどのように変化しようとも、時代の流れを的確にとらえ、我々はヒット商品を消費者に届けていきます。
        </p>
        <div className={style.img}>
          <Link to="/">
            <img src={HDLogo} alt="ARROWS & Company Ltd" />
          </Link>
        </div>
      </section>

      <div className={`footNav`}>
        <Link to="/service" className={`service`}>
          <p className={`mtM`}>SERVICE</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/about" className={`about`}>
          <p className={`mtM`}>ABOUT</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/recruit" className={`recruit`}>
          <p className={`mtM`}>RECRUIT</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
      </div>
    </Layout>
  )
}

export default Mind
